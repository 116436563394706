<template>
    <div class="accountformMobile">
      <!-- <img src="../../assets/account.png" class="accountBack"> -->


      <div class="topBack">
        
        <div class="topTitle">
            Student Registration Form
        </div>

        <img src="../../assets/toplogo.png" class="topLogo">
        
    
      </div>

      <div class="contentBack">
          <el-form :model="form" ref="form" :rules="formRules" label-position="left">

            <div class="mainRow">

              <div class="row">
                  <div class="rowItem">
                      <el-form-item label="NIM Project" prop="projectEvent">
                          <el-input :disabled="true" v-model="form.projectEvent"></el-input>
                      </el-form-item>
                  </div>
              </div>
              <div class="row">
                <div class="rowItem" >
                      <el-form-item label="Affiliation" prop="school">
                          <el-input :disabled="true" v-model="form.school"></el-input>
                      </el-form-item>
                  </div>
              </div>
              <div class="row">
                <div class="rowItem" >
                      <el-form-item label="Advisor Name" prop="contact_name">
                          <el-input :disabled="true" v-model="form.contact_name"></el-input>
                      </el-form-item>
                  </div>
              </div>
              <div class="row">
                  
                <div class="rowItemInfo" >
                    Please confirm the project, affiliation, and advisor
                    information. If any discrepancies, please contact
                    the person who shared the link with you.
                  </div>
              </div>

            </div>

            <div class="mainRow">

                <div class="mainTip">
                    Personal Information
                </div>

              <div class="row">
                  <div class="rowItem">
                      <el-form-item label="First Name in English" prop="givenName_pinyin">
                          <el-input  v-model="form.givenName_pinyin"></el-input>
                      </el-form-item>
                  </div>
              </div>
              <div class="row">
                <div class="rowItem" >
                      <el-form-item label="Last Name in English" prop="lastName_pinyin">
                          <el-input  v-model="form.lastName_pinyin"></el-input>
                      </el-form-item>
                  </div>
              </div>
              <div class="row">
                  <div class="rowItem">
                      <el-form-item label="Email Address" prop="email">
                          <el-input  v-model="form.email"></el-input>
                      </el-form-item>
                  </div>
              </div>
              <div class="row">
                <div class="rowItem" >
                      <el-form-item label="Phone Number" prop="mobile">
                          <el-input  v-model="form.mobile"></el-input>
                      </el-form-item>
                  </div>
              </div>

              <div class="row">
                  <el-form-item label="Gender" prop="gender">
                      <div style="display: inline-block;width:100%">
                          <el-radio-group v-model="form.gender">
                              <div style="width:500px;margin-bottom: 18px;"><el-radio label="Male" /></div>
                              <div style="width:500px;margin-bottom: 18px;"><el-radio label="Female" /></div>
                          </el-radio-group>
                      </div>
                  </el-form-item>
              </div>
              <div class="row">
                <el-form-item label="Year of High School Graduation" prop="graduation">
                      <div style="display: inline-block;width:100%">
                          <el-radio-group v-model="form.graduation">
                              <div style="width:500px;margin-bottom: 18px;"><el-radio label="2024" /></div>
                              <div style="width:500px;margin-bottom: 18px;"><el-radio label="2025" /></div>
                              <div style="width:500px;margin-bottom: 18px;"><el-radio label="2026" /></div>
                              <div style="width:500px;margin-bottom: 18px;"><el-radio label="2027"/></div>
                          </el-radio-group>
                      </div>
                  </el-form-item>
              </div>

            </div>

              <!-- <div class="row">
                  <div class="rowItem">
                      <el-form-item label="Are you also the program adviser?" prop="adviserStatus">
                          <el-input  v-model="form.adviserStatus"></el-input>
                      </el-form-item>
                  </div>
              </div>
              <div class="row" style="font-size: 16px;color: #333333;font-weight: bold;">Program Adviser</div>
              <div class="row" style="color:#666666">If you are not the adviser，please provide the adviser’s information in the following form.</div>
              <div class="row">
                  <div class="rowItem">
                      <el-form-item label="Name">
                          <el-input  v-model="form.adviserName"></el-input>
                      </el-form-item>
                  </div>
              </div>
              <div class="row">
                <div class="rowItem" >
                      <el-form-item label="Title">
                          <el-input  v-model="form.adviserTitle"></el-input>
                      </el-form-item>
                  </div>
              </div>
              <div class="row">
                  <div class="rowItem">
                      <el-form-item label="Email">
                          <el-input  v-model="form.adviserEmail"></el-input>
                      </el-form-item>
                  </div>
              </div> -->
              <div class="row" style="margin-bottom:50px">
                  <span class="submit" @click="submit">Submit</span>
              </div>
          </el-form>
      </div>
    </div>
  </template>

<script>
import { addNewNIMApplication ,getNIMProjectAndAccount} from '../../service.js'

import '../../assets/common/font.css'

export default {
  data () {
    return {
        advisorid: this.$route.query.advisorid,
        eventcode: this.$route.query.eventcode,
        projectcode:this.$route.query.projectcode,
        form: {
            projectEvent: '',
            school: '',
            contact_name: '',
            givenName_pinyin: '',
            lastName_pinyin: '',
            email: '',
            mobile: '',
            gender: '',
            graduation: '',
        
        },
        formRules: {
            givenName_pinyin: [
            { required: true, message: 'Please input', trigger: 'blur' }
            ],
            lastName_pinyin: [
            { required: true, message: 'Please input', trigger: 'blur' }
            ],
            gender: [
            { required: true, message: 'Please select', trigger: 'change' }
            ],
            graduation: [
            { required: true, message: 'Please select', trigger: 'change' }
            ],
            email: [
            { required: true, message: 'Please input', trigger: 'blur' }
            ],
            // mobile: [
            // { required: true, message: 'Please input', trigger: 'blur' }
            // ]
        }
    }
  },
  mounted() {

    this.getNIMProjectEvents()

  },
  methods: {

    getNIMProjectEvents(){

      let param = {
        project_code: this.projectcode,
        contact_id :this.advisorid,
        eventcode:this.eventcode
      };


      getNIMProjectAndAccount(param).then((res) => {

        if (res.code == 0) {
          this.form.contact_name = res.data.contact_name
          this.form.school = res.data.account_name
          this.form.projectEvent = res.data.getEvent

        } else {
            this.$message.error(res.msg);
        }
      });
    },

    submit () {
      this.$refs.form.validate((val) => {
        if (val) {

          if (this.form.email.indexOf("@") != -1 ){

          }else{
              this.$message.error('Email  Error');
              return
          }

          let param = {
                project_code: this.projectcode,
                contact_id :this.advisorid,
                event:this.form.projectEvent,
                lastName_pinyin:this.form.lastName_pinyin,
                givenName_pinyin:this.form.givenName_pinyin,
                gender:this.form.gender,
                graduation:this.form.graduation,
                mobile:this.form.mobile,
                email:this.form.email,
                student_edit_school:this.form.school

            };       

            addNewNIMApplication(param).then(res=>{
                
                if (res.data.code == 0) {

                    var infoTip = 'Your submission was successful! Please login to the system and complete the registration payment.'

                    if (res.data.data.newStudent == true){
                       infoTip = 'Your submission was successful! We\'ve created an account for you using the email address you provided. You can now access the system using the initial password "myideamatters".'
                    }
                    // this.$message.success('Added Successfully')
                    
                    this.$confirm(infoTip, 'Registration Confirmation', {
                        confirmButtonText: 'Log In',
                        cancelButtonText: 'Cancel',
                        customClass: 'mobileConfirm'
                    }).then(() => {
                      this.$router.push({
                        path: "/login",
                      });
                    })

                } else {
                    this.$message.error(res.msg);
                }
            })

        } else {
          this.$confirm('Please fill in the required information', 'Tips', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning',
            customClass: 'mobileConfirm'
          })
        }
      })
    }
  }
}
</script>

  <style scoped lang="scss">
  .accountformMobile{
      width: 100%;
      font-family: "DDINAlternateRegular";
      overflow: hidden;
      box-sizing: border-box;
      overflow-y: scroll;
      // background: url(../../assets/image/accountBack.png);
          background-position: 0 0;
          background-size: 100% 100%;
      .accountBack{
          width: 100%;
          display: block;
      }
      .contentBack{
        width: 100%;
        box-sizing: border-box;
      }
      .row{
          width: 90%;
          margin: 0 auto;
          justify-content: space-between;
          margin-bottom: 20px;
          display: flex;
          .rowItem{
            width: 100%;
          }
          .rowItemInfo{
            text-align: center;
            width: 400px;
            height: 50px;
            color: #204386;
            word-break:break-word;
          }
      }
      .el-checkbox-group{
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
      }
      .el-radio-group{
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
      }
      .submit{
          padding: 10px 70px;
          background: #204386;
          color: #fff;
          cursor: pointer;
      }

      .topBack{
        width: 100%;
        height: 150px;
        align-items: center;
      }
      .topTitle{
        width: 100%;
        text-align: center;
        margin: 0 auto;
        // justify-content: space-between;
        // margin-bottom: 20px;
        // display: flex;
        // margin-left: 70px;
        margin-top: 50px;
        font-size: 30px;
        font-weight: 600;
        color: #204386;
      }
      .topLogo{
        // position:absolute;
        margin-top: 20px;
        width: 120px;
        margin-left: 60%;
      }
      .mainTip{
        font-size: 30px;
        font-weight: 600;
        color: #204386;
        margin-left: 25px;
        margin-top: 25px;
      }

      .mainRow{
        border: 1px solid #204386;
        border-radius: 5px;
        width: 90%;
        margin: 0 auto;
        justify-content: space-between;
        margin-bottom: 20px;
        // display: flex;
      }
  }
  </style>
  <style lang="scss">
  .accountform{
      .el-form-item__label{
          font-size: 16px;
          color: #333333;
          font-weight: bold;
      }
      .el-checkbox__label{
          font-size: 16px;
          color: #666666;
      }
      .el-radio__label{
          font-size: 16px;
          color: #666666;
      }
      .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
          background-color: #204386;
          border-color: #204386;
      }
      .el-checkbox__input.is-checked+.el-checkbox__label{
          color: #204386;
      }
      .el-radio__input.is-checked .el-radio__inner{
          background-color: #204386;
          border-color: #204386;
      }
      .el-radio__input.is-checked+.el-radio__label{
          color: #204386;
      }
  }
  .mobileConfirm{
    width: 80%;
    .el-message-box__container{
        display: flex;
        .el-message-box__status{
            position: relative;
            flex-shrink: 0;
            transform:none
        }
        .el-message-box__message{
            padding-left: 10px;
        }
    }
  }
  </style>
